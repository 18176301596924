import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify'
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../components/CustomSignUp"
import { listProjects } from '../../../graphql/queries'

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import awsExports from "../../../aws-exports"
import { Hub } from 'aws-amplify';
Amplify.configure(awsExports)

const SignupLink = (props) => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange('signUp')}>Sign Up here</button>
      </div>
    )}
  </div>
)


const ProfilePage = () => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)
  const [projects, setProjects] = useState([])

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      console.log(user.signInUserSession.accessToken.payload["cognito:groups"])
      // console.log("attributes: ", user.attributes)
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        console.log('user signed in');
        getUser()
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signOut':
        console.log('user signed out');
        break;
      case 'signIn_failure':
        console.log('user sign in failed');
        break;
      case 'configured':
        console.log('the Auth module is configured');
    }
  });

  const UserDetails = props => {
    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)

    if (props.authState === "signedIn" && user) {
      const address = JSON.parse(user.attributes.address)
      console.log("user: ", user)
      return (
        <div className="always-on">
          <h1>Hi {user.attributes.email}</h1>
          {/* <p>belongs to pool: {userPool}</p> */}
          <ul>
            <li>{address.street_address}</li>
            <li>{address.locality}</li>
            <li>{address.postal_code}</li>
            <li>{address.country}</li>

          </ul>
        </div>
      )
    }
    return null
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }
  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        // signUpConfig={mySignUpConfig}
        >
          <UserDetails />
          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Contribute" />
      <Link to="/">Go back to the homepage</Link>
    </section>
  )
}

export default ProfilePage